import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import InReview from './pages/error/InReview'
import EligibilityRejected from './pages/error/EligibilityRejected'
import GenericError from './pages/error/GenericError'
import HomePage from './pages/HomePage'
import NavigationBar from './components/layout/NavigationBar'
import NotFound from './pages/error/NotFound'
import { ThemeProvider } from '@mui/material/styles'
import routes from './routes'
import theme from './assets/theme'
import SoleTraderDetailsInUse from './pages/error/SoleTraderDetailsInUse'
import FlagProvider from '@unleash/proxy-client-react'
import { unleashConfig } from './unleash/unleashConfig'
import SegmentWrapper from './utils/metrics/segmentWindow'
import { useEffect } from 'react'

const queryClient = new QueryClient()

function App() {
  useEffect(() => {
    SegmentWrapper.page('OnboardingJourney')
  }, [])

  return (
    <FlagProvider config={unleashConfig}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <NavigationBar>
              <Routes>
                <Route path={routes.home} element={<HomePage />} />
                <Route path={routes.inReview} element={<InReview />} />
                <Route path={routes.rejected} element={<EligibilityRejected />} />
                <Route path={routes.error} element={<GenericError />} />
                <Route path={routes.notFound} element={<NotFound />} />
                <Route path={routes.phoneNumberDuplicate} element={<SoleTraderDetailsInUse />} />
              </Routes>
            </NavigationBar>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </FlagProvider>
  )
}

export default App
