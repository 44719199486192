import Button from '@mui/material/Button'
import ErrorPageContainer, { StyledErrorPageButtonContainer } from './ErrorPageContainer'
import Box from '@mui/material/Box'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import { OnboardingJourneySource } from '../../types/enums/OnboardingJourneySource'

const ModularCheckHaveFailed = (data: OnboardingJourney) => {
  const handleReturnClick = () => {
    window.location.href = data.abandonedUrl
  }

  return (
    <ErrorPageContainer title="Onboarding failed">
      <Box data-cy="message">Sorry, but you did not successfully pass the onboarding.</Box>
      <br />
      <Box data-cy="add-on">
        Unfortunately, we can't process your payment through Kriya at this time.{' '}
        {data.source === OnboardingJourneySource.PaymentJourney &&
          `Please go back to ${data.merchant.name}{' '}
        and select a different payment method to finalise your order.`}
      </Box>
      {data.abandonedUrl && (
        <StyledErrorPageButtonContainer>
          <Button variant="contained" id="return-button" onClick={handleReturnClick}>
            Return to {data.merchant.name}
          </Button>
        </StyledErrorPageButtonContainer>
      )}
    </ErrorPageContainer>
  )
}

export default ModularCheckHaveFailed
