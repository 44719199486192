import React from 'react'
import { OnboardingJourney } from '../../../types/OnboardingJourney'
import { useNavigate } from 'react-router-dom'
import { CheckStatus } from '../../../types/enums/CheckStatus'
import { OnboardingJourneyCheck } from '../../../types/OnboardingJourneyCheck'
import PageLoading from '../../../components/loading/PageLoading'
import { Check } from './components/Check'
import { useCheckOnboardingStatus } from '../../../custom-hooks/useCheckOnboardingStatus'

const GetOnboardingJourneyCheckToRun = (checks: OnboardingJourneyCheck[]) => {
  return checks
    .filter((c) => c.status == CheckStatus.Submitted && c.displayOrder != null)
    ?.sort((a, b) => a.displayOrder! - b.displayOrder!)
    ?.at(0)
}

const CheckWizardPage = (data: OnboardingJourney) => {
  const { startPolling, isPolling } = useCheckOnboardingStatus(data.id)
  const navigate = useNavigate()

  if (data.checks == undefined) {
    navigate('/error')
  }

  const checkToRun = GetOnboardingJourneyCheckToRun(data.checks!)

  if (checkToRun) {
    return <Check onboardingJourneyId={data.id} checkType={checkToRun.type} />
  }

  if (!isPolling) {
    startPolling(true)
  }

  return <PageLoading title="Onboarding" body="Please don't close the window."></PageLoading>
}

export default CheckWizardPage
