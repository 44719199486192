import Box from '@mui/material/Box'
import { Controller, Path, useForm } from 'react-hook-form'
import TextInput from '../../components/TextInput'
import { StyledButtonsContainer } from '../../components/layout/Container'
import ReturnToMartketplaceDialog from '../../components/returnToMarketplace'
import captionBoxStyling from './captionBoxStyling'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import AddressForm from '../../components/address/AddressForm'
import { Address } from '../../types/Address'
import ReadOnlyField from '../../components/ReadOnlyField'
import React from 'react'
import { useOnboardSoleTrader } from '../../custom-hooks/useOnboardSoleTrader'
import { maxLength150, maxLength75 } from '../../utils/validationConstants'
import { useNavigate, useParams } from 'react-router-dom'
import { useOnboardingJourneyInfo } from '../../custom-hooks/useOnboardingJourneyQuery'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { defaultCountryCode, defaultCountryName } from '../../consts'
import ErrorText from '../error/FormErrors'
import ActionButton from '../../components/ActionButton'
import routes from '../../routes'
import { emailPattern } from '../../utils/emailRegExp'
import { Person } from '../../types/Person'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'

export interface SoleTraderInformationForm extends Address {
  soleTraderEmail?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  mobilePhoneNumber?: string
  annualTurnover?: number
}

const requiredFirstNameError = 'Please enter your first name'
const requiredLastNameError = 'Please enter your last name'
const requiredEmailError = 'Please enter your email'
const invalidEmailError = 'Please enter a valid email address'
const requiredTurnoverError = 'Please enter your annual turnover'
const invalidTurnoverError = 'Please enter a valid annual turnover'
const requiredDateOfBirthError = 'Please enter your date of birth'
const invalidPhoneNumberError = 'Please enter a valid mobile phone number'

const dateOfBirthPattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/

interface ReadOnlyFieldDetails {
  formName: string
  labelName: string
}

interface SoleTraderIdentifierDetails {
  isEmailRequired: boolean
  isPhoneNumberRequired: boolean
  readOnlyFields: ReadOnlyFieldDetails[]
}

const SoleTraderDetailsForm = () => {
  const navigate = useNavigate()
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const { data } = useOnboardingJourneyInfo(merchantId, merchantCompanyIdentifier)
  const onboardingJourney = data!

  const getIdentifierDetails = (person: Person): SoleTraderIdentifierDetails => {
    const isEmailRequired = !person.email || person.email === ''
    const isPhoneNumberRequired = !person.phoneNumber || person.phoneNumber === ''

    const readOnlyFields: ReadOnlyFieldDetails[] = []

    if (!isEmailRequired) {
      readOnlyFields.push({
        formName: 'soleTraderEmail',
        labelName: "Sole trader's email"
      })
    }

    if (!isPhoneNumberRequired) {
      readOnlyFields.push({
        formName: 'mobilePhoneNumber',
        labelName: "Sole trader's mobile phone number"
      })
    }

    return {
      isEmailRequired: isEmailRequired,
      isPhoneNumberRequired: isPhoneNumberRequired,
      readOnlyFields: readOnlyFields
    }
  }

  const identifierDetails = getIdentifierDetails(onboardingJourney.director)

  const form = useForm<SoleTraderInformationForm>({
    mode: 'onChange',
    defaultValues: {
      soleTraderEmail: onboardingJourney.director?.email || '',
      mobilePhoneNumber: onboardingJourney.director?.phoneNumber || '',
      country: defaultCountryName
    }
  })

  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors }
  } = form

  register('addressLine1', { required: true })
  register('postCode', { required: true })
  register('city', { required: true })

  const onSubmit = (formValues: SoleTraderInformationForm) => {
    if (!isMutationLoading) {
      const [day, month, year] = formValues.dateOfBirth!.split('/')
      const dateOfBirth = new Date(`${month}/${day}/${year}`)

      onboardSoleTraderMutation({
        id: onboardingJourney.id,
        director: {
          firstName: formValues.firstName!,
          lastName: formValues.lastName!,
          email: formValues.soleTraderEmail!,
          phoneNumber: formValues.mobilePhoneNumber!,
          dateOfBirth: dateOfBirth,
          addressLine1: formValues.addressLine1,
          addressLine2: formValues.addressLine2,
          postCode: formValues.postCode,
          region: formValues.region,
          city: formValues.city,
          country: formValues.country
        },
        turnover: formValues.annualTurnover!
      })
    }
  }

  const { mutate: onboardSoleTraderMutation, isLoading: isMutationLoading } = useOnboardSoleTrader({
    onError: (error) => {
      if (error.status === 409) {
        navigate(`/${merchantId!}/${merchantCompanyIdentifier!}/phone-number-duplicate`)
      } else {
        navigate(routes.error)
      }
    }
  })
  const validateDOB = (value: string) => {
    const [day, month, year] = value.split('/')
    const inputDate = new Date(`${month}/${day}/${year}`)
    const maxPossibleDOB = new Date()
    maxPossibleDOB.setFullYear(maxPossibleDOB.getFullYear() - 18)
    if (inputDate > maxPossibleDOB) return 'Director should be older than 18 years old'

    const minPossibleDOB = new Date()
    minPossibleDOB.setFullYear(minPossibleDOB.getFullYear() - 90)
    if (inputDate < minPossibleDOB) return 'Director should be younger than 90 years old'
    return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="soletraderdetails-information-form">
      <Box sx={captionBoxStyling}>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: theme.spacing(16) }
          }}
        ></Box>

        {identifierDetails.readOnlyFields.map((field, index) => (
          <ReadOnlyField
            key={index}
            label={field.labelName}
            name={field.formName as Path<SoleTraderInformationForm>}
            getValues={getValues}
          />
        ))}

        <TextInput
          label="Sole trader's first name"
          name="firstName"
          register={register}
          error={errors.firstName}
          validation={{
            required: requiredFirstNameError,
            validate: (value: string) => value.trim().length > 0 || requiredFirstNameError,
            maxLength: maxLength75
          }}
        />

        <TextInput
          label="Sole trader's last name"
          name="lastName"
          register={register}
          error={errors.lastName}
          validation={{
            required: requiredLastNameError,
            validate: (value: string) => value.trim().length > 0 || requiredLastNameError,
            maxLength: maxLength75
          }}
        />

        {identifierDetails.isEmailRequired && (
          <TextInput
            label="Sole trader's email"
            name="soleTraderEmail"
            register={register}
            error={errors.soleTraderEmail}
            validation={{
              required: requiredEmailError,
              pattern: {
                value: emailPattern,
                message: invalidEmailError
              },
              maxLength: maxLength150
            }}
          />
        )}

        <TextInput
          label="Sole trader's date of birth"
          placeholder="DD/MM/YYYY"
          name="dateOfBirth"
          register={register}
          error={errors.dateOfBirth}
          validation={{
            required: requiredDateOfBirthError,
            pattern: {
              value: dateOfBirthPattern,
              message: requiredDateOfBirthError
            },
            validate: validateDOB
          }}
        />

        {identifierDetails.isPhoneNumberRequired && (
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => !value || matchIsValidTel(value, { onlyCountries: [defaultCountryCode] })
            }}
            render={({ field, fieldState }) => (
              <Box>
                <FormControl fullWidth>
                  <FormLabel htmlFor="mobilePhoneNumber">Sole trader's mobile phone number</FormLabel>
                  <MuiTelInput
                    {...field}
                    sx={{ width: '100%' }}
                    data-cy="mobilePhoneNumber"
                    defaultCountry={defaultCountryCode}
                    onlyCountries={[defaultCountryCode]}
                    disableDropdown={true}
                    error={fieldState.invalid}
                  />
                </FormControl>
                {fieldState.invalid && <ErrorText id={field.name}>{invalidPhoneNumberError}</ErrorText>}
              </Box>
            )}
            name="mobilePhoneNumber"
          />
        )}

        <TextInput
          label="Sole trader's annual turnover"
          name="annualTurnover"
          register={register}
          error={errors.annualTurnover}
          validation={{
            required: requiredTurnoverError,
            validate: (value: string) => {
              const trimmedValue = value.trim()
              const numberValue = Number(trimmedValue)
              const isNumber = !isNaN(numberValue)
              return (
                (trimmedValue.length > 0 && isNumber && numberValue > 0 && numberValue < Number.MAX_SAFE_INTEGER) ||
                invalidTurnoverError
              )
            }
          }}
          startAdornment={<InputAdornment position="start">£</InputAdornment>}
        />
        <br />
        <Typography variant="h2">Sole trader's residential address </Typography>
        <AddressForm form={form} />

        <div style={{ marginTop: theme.spacing(16) }}>
          <Typography variant="helperText2" sx={{ color: theme.palette.common.textGrey900 }}>
            By clicking "Continue", you agree to Kriya using your details to assess your eligibility for credit which
            may include soft checks at credit bureaus.
          </Typography>
        </div>

        <StyledButtonsContainer>
          <ActionButton
            id="continue-button"
            disabled={!form.formState.isValid || isMutationLoading}
            loading={isMutationLoading}
          >
            Continue
          </ActionButton>
          {onboardingJourney.abandonedUrl && (
            <ReturnToMartketplaceDialog
              marketplace={onboardingJourney.merchant.name}
              disabled={isMutationLoading}
              redirectUrl={onboardingJourney.abandonedUrl}
            ></ReturnToMartketplaceDialog>
          )}
        </StyledButtonsContainer>
      </Box>
    </form>
  )
}

export default SoleTraderDetailsForm
