import { FieldError, FieldValues, Path, UseFormGetValues, UseFormRegister } from 'react-hook-form'

import ErrorText from '../pages/error/FormErrors'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import React from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ReadOnlyField from './ReadOnlyField'

interface BaseProps<T extends FieldValues> {
  register: UseFormRegister<T>
  name: Path<T>
  label: string
  optional?: boolean
  validation?: { [key: string]: string | boolean | {} }
  error?: FieldError
  placeholder?: string
  showErrorIcon?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  startAdornment?: React.ReactNode
  readonly?: boolean
}

type Props<T extends FieldValues> = BaseProps<T> &
  (BaseProps<T>['readonly'] extends true ? { getValues: UseFormGetValues<T> } : { getValues?: UseFormGetValues<T> })

const TextInput: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  register,
  getValues,
  name,
  label,
  validation = {},
  error,
  placeholder = '',
  optional,
  showErrorIcon,
  disabled = false,
  onChange,
  startAdornment,
  readonly = false
}) => {
  if (readonly) {
    return <ReadOnlyField name={name} label={label} getValues={getValues!} />
  }

  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        {optional ? (
          <>
            {label} <Typography variant="inputLabel">(optional)</Typography>{' '}
          </>
        ) : (
          label
        )}
      </FormLabel>

      <TextField
        {...register(name, validation)}
        id={name}
        data-cy={name}
        placeholder={placeholder}
        error={error ? true : false}
        aria-invalid={error ? 'true' : 'false'}
        disabled={disabled}
        aria-describedby={error && `${name}-error`}
        InputProps={{
          startAdornment: startAdornment
        }}
        {...(onChange ? { onChange } : {})}
      />
      {error && (
        <ErrorText id={name} showIcon={showErrorIcon}>
          {error.message}
        </ErrorText>
      )}
    </FormControl>
  )
}

export default TextInput
